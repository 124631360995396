import React from 'react';
import '../App.css';
import { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginUsers } from '../features/authenticationSlice';
import { Navigate } from 'react-router-dom';

function Login(){
   
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.currentUser)
    const error = useSelector(state => state.error)
    useEffect(() => {
       
        console.log('currentUser')
    console.log(currentUser)  
      }, []);
    function submitLogin(e) {
        e.preventDefault();
        dispatch(fetchLoginUsers({'username':username, 'password':password}))   
    }

    if (currentUser) {
        return(
            <Navigate to="/" />
        )
    }      
    return(
    
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Login
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" action="#" method="POST">
                    <div>
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                            Benutzername
                        </label>
                        <div className="mt-2">
                            <input
                            id="username"
                            name="username"
                            type="username"
                            autoComplete="username"
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={username} 
                            onChange={e => setUsername(e.target.value)}
                            />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                            Passwort
                            </label>
                        </div>
                        <div className="mt-2">
                            <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        {error && <p className='text-red-500 text-xs font-medium'>Benutzername oder Passwort wurde falsch eingegeben</p>}
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={e => submitLogin(e)}
                        >
                            Anmelden
                        </button>
                        
                    </div>
                </form>
            </div>
        </div>
        
    
        )
}

export default Login;