import React, { Component } from 'react';
import '../App.css';


function Footer(){

return(
    <footer className="mt-10 border-t border-gray-200 bg-gray-200 shadow shadow-gray-300">
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="https://flowbite.com/" className="hover:underline">Berlin Recycling</a>. All Rights Reserved. 
             </span>
        </div>
    </footer>
    )
}

export default Footer;