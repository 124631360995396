import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PdfDownloadLinkComponent from './PdfDownloadLinkComponent';

import TextField from "@mui/material/TextField";
import { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';



function AuftragBehaelterRow(props) {
  const { auftragRow } = props;
  const[auftragBehaelterOpen, setAuftragBehaelterOpen] = React.useState(false);
  return (
    <>
    <TableRow key={auftragRow.id}>
      <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setAuftragBehaelterOpen(!auftragBehaelterOpen)}
          >
            {auftragBehaelterOpen ? <p>Schließen</p> : <p>Öffnen</p>}
          </IconButton>
      </TableCell>
      <TableCell component="th" scope="row">
        {auftragRow.erstellt_von}
      </TableCell>
      <TableCell>{auftragRow.erstellt_um}</TableCell>
      <TableCell>{auftragRow.bemerkungsfeld}</TableCell>
    </TableRow>

    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
       
          <Box sx={{ margin: 1 }}>
            <Collapse in={auftragBehaelterOpen} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>status</TableCell>
                  <TableCell>typ</TableCell>
                  <TableCell>erstellt_um</TableCell>
                  <TableCell>ausführungsdatum</TableCell>                   
                </TableRow>
              </TableHead>
              <TableBody>
                {auftragRow.auftrag_behaelters.map((auftragBehaelterRow) => (
                  <>
                  <TableRow key={auftragBehaelterRow.id}>
                    <TableCell>{auftragBehaelterRow.status}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {auftragBehaelterRow.typ}
                    </TableCell>
                    <TableCell>{auftragBehaelterRow.erstellt_um}</TableCell>
                    <TableCell >{auftragBehaelterRow.ausführungsdatum}</TableCell>
                  </TableRow>
                  </>
                ))}
                
              </TableBody>
            </Table>
            </Collapse>
          </Box>
      
      </TableCell>
    </TableRow>
    </>
  )
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const[behaelterOpen, setBehaelterOpen] = React.useState(false);
  const[auftragOpen, setAuftragrOpen] = React.useState(false);
  
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <p>Schließen</p> : <p>Öffnen</p>}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.bezeichnung}
        </TableCell>
        <TableCell align="right">{row.kundentyp}</TableCell>
        <TableCell align="right">{row.halle}</TableCell>
        <TableCell align="right">{row.stand}</TableCell>
        <TableCell align="right">{row.erstellt_um}</TableCell>

      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
              <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setBehaelterOpen(!behaelterOpen)}
          >
            <p>Behälter</p>
          </IconButton>
               
              </Typography>
              <Collapse in={behaelterOpen} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Abfallart</TableCell>
                    <TableCell>Behältergröße</TableCell>
                    <TableCell align="right">Einheit</TableCell>
                    <TableCell align="right">Behältertyp</TableCell>        
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.behaelters.map((behaelterRow) => (
                    <TableRow key={behaelterRow.uuid}>
                      <TableCell component="th" scope="row">         
                        <PdfDownloadLinkComponent bezeichnung={row.bezeichnung} halle={row.halle} stand={row.stand} behaelterId={behaelterRow.id} behaelterUuid={behaelterRow.uuid}></PdfDownloadLinkComponent>                     
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {behaelterRow.abfallart}
                      </TableCell>
                      <TableCell>{behaelterRow.behaelter_groesse}</TableCell>
                      <TableCell align="right">{behaelterRow.einheit}</TableCell>
                      <TableCell align="right">
                        {behaelterRow.behaelter_typ}
                      </TableCell>                        
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </Collapse>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
              <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setAuftragrOpen(!auftragOpen)}
          >
            <p>Aufträge</p>

            
          </IconButton>
              </Typography>
              <Collapse in={auftragOpen} timeout="auto" unmountOnExit>
              <Link to={'/bestellformular?kunden_id='+row.id}>+ Neuer Auftrag</Link>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Erstellt von</TableCell>
                    <TableCell>Erstellung Zeit</TableCell>
                    <TableCell>Bemerkungsfeld</TableCell>              
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.auftrags.map((auftragRow) => (
                   <AuftragBehaelterRow auftragRow={auftragRow} />
                  ))}
                  
                </TableBody>
              </Table>
              </Collapse>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export default function CollapsibleTable() {
  const allekunden = useSelector(state => state.kunden)
  const [searchQuery, setSearchQuery] = useState("");
  console.log('allekunden', allekunden)
  const [rows, setRows] = useState(allekunden);
  
  useEffect(() => {
  setRows(allekunden)  
  }, [allekunden]);
 
  const requestSearch = () => {
  
      const filteredRows = allekunden.filter((row) => {
        return row.bezeichnung.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.kundentyp.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.behaelters.some(subItem => subItem.abfallart.toLowerCase().includes(searchQuery.toLowerCase()))
      });
      setRows(filteredRows);
      
  };


  return (
    <TableContainer component={Paper}>
    <TextField
      id="search-bar"
      className="text"
      style={{ margin: '20px' }}
      onInput={(e) => {
        setSearchQuery(e.target.value);
      }}
      
      label=""
      variant="outlined"
      placeholder="Search..."
      size="small"
    />
    <button  style={{ marginTop: '20px' }} onClick={requestSearch}><SearchIcon></SearchIcon></button>
   
    
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Bezeichnung</TableCell>
            <TableCell align="right">Kundentyp</TableCell>
            <TableCell align="right">Halle</TableCell>
            <TableCell align="right">Stand</TableCell>
            <TableCell align="right">Erstellung Zeit</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
