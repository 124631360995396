import React from 'react';
import '../App.css';
import {useLocation} from 'react-router-dom';

function Errorpage(){
  const location = useLocation();
    return(
    <div>
        <h1 className='mt-10 text-5xl text-center '>
        <span className='text-9xl'>500</span> <br />
        Internal server error
        </h1>
        <p className='mt-2 text-center'>{location.state.error}</p>
    </div>  
    )
}
  
  


export default Errorpage;