import { createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from '../axiosConfig';

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
})

const initialState = {
    currentUser : false,
    loading:false,
    error:'',
    kunden:[],
    offline:false,
    benutzerName:''
}

export const fetchLoginUsers = createAsyncThunk('user/fetchLoginUsers',async(fetchData,{rejectWithValue}) => {
  try {
    const response = await client.post('/bestellformular/api/v1/login', fetchData);
    return response.data
  } catch (err) {
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
} )

export const fetchLogoutUsers = createAsyncThunk('user/fetchLogoutUsers',async(fetchData,{rejectWithValue}) => {
  try {
    const response = await client.post('/bestellformular/api/v1/logout', fetchData);
    return response.data
  } catch (err) {
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
} )

export const alleKunden = createAsyncThunk('user/alleKunden',async() => {
  const response = await client.get('/bestellformular/api/v1/allekunden');
  return response.data  
} )

export const userCheck = createAsyncThunk('user/userCheck',async() => {
  const config = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    withCredentials:true
};
  const response = await client.get('/bestellformular/api/v1/authenticated', config);
  return response.data
} )

export const healty = createAsyncThunk('user/healty',async() => {
  const response = await client.get('/bestellformular/api/v1/healty');
  return response.data 
} )

export const authenticationSlice = createSlice({
  name: 'authentication' ,
  initialState,
  reducers:{},
  extraReducers: (builder) => {
    
    builder.addCase(fetchLoginUsers.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchLoginUsers.fulfilled, (state) =>{
      state.loading = false
      state.currentUser = true
      state.error = ''
    })
    builder.addCase(fetchLoginUsers.rejected, (state,action) => {
      state.loading = false
      state.currentUser = false
      state.error = action.error.message
    })
    builder.addCase(fetchLogoutUsers.fulfilled, (state) =>{
      state.loading = false
      state.currentUser = false
      state.error = ''
    })

    builder.addCase(alleKunden.fulfilled, (state, action) => {
      localStorage.setItem('kunden',JSON.stringify(action.payload))
      state.loading = false;
      state.kunden = action.payload;
      state.error = '';
    })
    builder.addCase(alleKunden.rejected, (state,action) => {
      let localstorage_kunden =  localStorage.getItem('kunden')
      state.kunden = JSON.parse(localstorage_kunden)
      state.loading = false
      state.error = action.error.message
    })

    builder.addCase(userCheck.fulfilled, (state,action) =>{
      state.loading = false
      state.currentUser = true
      state.benutzerName = action.payload.user;
      state.error = ''
    })

    builder.addCase(healty.fulfilled, (state) =>{
      state.loading = false
      state.offline = false
      state.error = ''
    })
    builder.addCase(healty.rejected, (state,action) => {
      state.loading = false
      state.offline = true
      state.error = action.error.message
    })
  }

})

export default authenticationSlice.reducer