import React from 'react';
import '../App.css';
import { Bars3BottomRightIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { useState } from 'react';
import logo from '../assets/images/Logo_Berlin_Recycling_GmbH.png'
import { useDispatch } from 'react-redux';
import { fetchLogoutUsers } from '../features/authenticationSlice';
import { Link } from 'react-router-dom';

function Navbar(){
    let Links =[
        // {name:"Kunden Erstellung", link:"/kundenerstellung"}
      ];
    const [open, setOpen] =useState(false);
    const dispatch = useDispatch()
   
    function submitLogout (e) {
        e.preventDefault();
        dispatch(fetchLogoutUsers({'currentUser':true}))

    }
    return (  
        <div className='shadow-md w-full top-0 left-0'>
           <div className='md:flex items-center justify-between bg-white py-4 md:px-10 px-7'>
           
            <div className='font-bold text-2xl cursor-pointer flex items-center gap-1'>
                <img src={logo}></img>
                <span>Bestellformular</span>
            </div>
           
            <div onClick={()=>setOpen(!open)} className='absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7'>
                {
                    open ? <XMarkIcon/> : <Bars3BottomRightIcon />
                }
            </div>
           
            <ul className={`xs:mt-12 md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-12' : 'top-[-490px]'}`}>
                {
                    Links.map((link) => (
                    <li className='md:ml-8 md:my-0 my-7 font-semibold'>
                        <Link to={link.link}>{link.name}</Link>
                    </li>))
                }
                <button onClick={e => submitLogout(e)} className='btn bg-blue-600 text-white md:ml-8 font-semibold px-3 py-1 rounded duration-500 md:static'>Log Out</button>
            </ul>
           
           </div>
        </div>
        
    );
}

export default Navbar;