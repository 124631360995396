import React from 'react';
import '../App.css';
import {useState, useEffect,useRef } from "react"; 
import DatePicker from "react-datepicker"; 
import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from 'date-fns/locale/de';
import "react-datepicker/dist/react-datepicker.css";
import Footer from './Footer';
import SignatureCanvas from 'react-signature-canvas'
import Produktcard from './Produktcard';
import { format } from 'date-fns';
import logo from '../assets/images/Logo_Berlin_Recycling_GmbH.png'
import { useSelector, useDispatch } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { useNavigate, useLocation } from "react-router-dom";
import {userCheck, healty} from '../features/authenticationSlice';
import axios from '../axiosConfig';

registerLocale('de', de);
setDefaultLocale('de');

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
})

function Bestellformular(){
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(userCheck())   
    }, []);
     
    const queryParameters = new URLSearchParams(window.location.search)
    const url_id = queryParameters.get("url_id") 
    const kunden_data_endpoint = "/bestellformular/api/v1/kunden_data/?url_id="+ url_id

    const kunden_id = queryParameters.get("kunden_id") 
    const kunden_data_endpoint_mit_kunden_id = "/bestellformular/api/v1/kunden_data/?kunden_id="+ kunden_id

    const currentUser = useSelector(state => state.currentUser)
    const benutzerName = useSelector(state => state.benutzerName)
    
    const [kundenData, setKundenData] = useState([{}]);
    const [behaelterData, setBehaelterData] = useState([{}]);

    const [produktData, setProduktData] = useState('');
    const [typData, setTypData] = useState('');
    const [ausführungsdatum, setAusführungsdatum] = useState(new Date());
   
    const [produktCardData, setProduktCardData] = useState([])
    
    const [bemerkungsfeld, setBemerkungsfeld] = useState('')
    
    const sigPadRef = useRef(null);
    // für Erorrs
    const [signatureEmpty, setSignatureEmpty] = useState(false);
    const [cardEmpty, setCardEmpty] = useState(false);
    
    const randomId = nanoid()

    const navigate = useNavigate();
    const [showLoseLadung, setShowLoseLadung] = useState('false')
    const [loseLadung, setLoseLadung] = useState(1);

    const [lskundenBehaaelterData, setLskundenBehaaelterData] = useState([{}])
    const [lskundenData, setLskundenData] = useState([{}]);

    const location = useLocation();
    
    const offline = useSelector(state => state.offline)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
          setLoading(false)
        }, 200)
    }, []);

    useEffect(() => {
        const fetchData = async () => {
        const params = new URLSearchParams(location.search);
        const kundenId = params.get('kunden_id');

        if (kundenId) {
            try {
             await fetch(`/bestellformular?kunden_id=${kundenId}`);
            } catch (error) {
            }
        }
        };

        fetchData();
    }, [location]);

    useEffect(() => {
    if (behaelterData != undefined){
        const behaelterTyp = behaelterData.find(item => item.id == produktData);
        if (behaelterTyp != undefined){
            if (behaelterTyp.behaelter_typ == 'Container'){
                setShowLoseLadung(true)
            }
            else{
                setShowLoseLadung(false)
                setLoseLadung(1)
            }
        }
    }
    }, [typData,produktData]);

    function auftragSpeichern(e) {
        e.preventDefault()     
        const dataUrl = sigPadRef.current.toDataURL();

        const currentDate = new Date().toISOString().split('T')[0]

        const auftragbehaelter_rows = [];
        const behaelters = []
        for (let i = 0; i < produktCardData.length; i++) {
            auftragbehaelter_rows.push({
                erstellt_um:currentDate,
                typ:produktCardData[i].typ,
                status: "Erstellt",
                behaelter:produktCardData[i].produkt.id,
                ausführungsdatum:format(produktCardData[i].ausführungsdatum, 'yyyy-MM-dd'),
                lose_ladung:produktCardData[i].lose_ladung
            });
            
            behaelters.push({               
                kunden:kundenData.id,
                abfallart:produktCardData[i].produkt.abfallart,
                behaelter_groesse:produktCardData[i].produkt.behaelter_groesse,
                einheit:produktCardData[i].produkt.einheit,
                behaelter_typ:produktCardData[i].produkt.behaelter_typ,
                active:true
            }); 
        }
        
        const auftragData = {
            kunden:kundenData.id,
            bemerkungsfeld:bemerkungsfeld,
            image_b64:dataUrl,
            erstellt_von:benutzerName,
            erstellt_um:currentDate,
            auftrag_behaelters:auftragbehaelter_rows,
            behaelters:behaelters
        }

        if(produktCardData.length == 0){
            setCardEmpty(true);
        }
        else if (sigPadRef.current.isEmpty()) {
            setSignatureEmpty(true);
        }else{
            client.post('/bestellformular/api/v1/auftrag/', auftragData)
            .then(function(res) {
                if (!currentUser){
                    navigate("/successpage");
                }
                else{
                    navigate("/uebersichtseite");
                }    
            })
            .catch((error) => {
                // Auftrag in LocalStorage in Offline Speichern
                if (offline){
                    let lsAuftragData = localStorage.getItem('auftragData')
                    let offlineAuftragArr = null
                    try {
                        offlineAuftragArr = JSON.parse(lsAuftragData)
                    } catch (e) {     
                    }

                    if (offlineAuftragArr){                         
                        offlineAuftragArr.push(auftragData)
                        localStorage.setItem('auftragData', JSON.stringify(offlineAuftragArr))
                    }
                    else{
                        localStorage.setItem('auftragData', JSON.stringify([auftragData])) 
                    }
                   
                    navigate("/uebersichtseite");

                }else{
                    navigate("/errorpage", {state:{error:error.response.data.error}});
                }
               
            }) 
        }
    }

    const handleClear = (e) => {
        e.preventDefault()
        sigPadRef.current.clear();
      };
       
    useEffect(() => {
        dispatch(healty())
        if(loading == false){
            if(kunden_id != null && !currentUser && !offline){
                navigate("/login");
            }
            else if ((kunden_id != null && currentUser) || (offline && kunden_id != null)){
                client.get(
                    kunden_data_endpoint_mit_kunden_id,
                ).then(function(res) {                  
                    setKundenData(res.data);

                    // localstorage
                    localStorage.setItem('kunden_id',kunden_id )
                    localStorage.setItem('kundenBehaaelterData' ,JSON.stringify(res.data.behaelters))
                    localStorage.setItem('kundenData',JSON.stringify(res.data))
                   
                    client.get(              
                        "/bestellformular/api/v1/all_produkte/",
                    ).then(function(res) {
                        localStorage.setItem('lsAllBehaelterData', JSON.stringify(res.data))
                    }) 

                    if(typData == 'Abziehen'|| typData == 'Leerung'){
                        setBehaelterData(res.data.behaelters)
                    }
                    else if(typData == 'Gestellung' || typData == 'Abholung'){
                        client.get(
                            "/bestellformular/api/v1/all_produkte/",
                        
                        ).then(function(res) {
                            setBehaelterData(res.data)
                        })
                    }else{
                        setBehaelterData()
                    }
                })
                .catch((error) => {                   
                    let localstorage_kundenBehaaelterData = localStorage.getItem('kundenBehaaelterData')
                
                    setLskundenBehaaelterData(JSON.parse(localstorage_kundenBehaaelterData))
                
                    let localstorage_kundenData = localStorage.getItem('kundenData')
                    setLskundenData(JSON.parse(localstorage_kundenData))
                    
                    setKundenData(lskundenData);
             
                    if(typData == 'Abziehen'|| typData == 'Leerung'){
                        setBehaelterData(lskundenBehaaelterData)
                    }
                    else if(typData == 'Gestellung' || typData == 'Abholung'){
                        let localstorage_lsAllBehaelterData = localStorage.getItem('lsAllBehaelterData')
                        setBehaelterData(JSON.parse(localstorage_lsAllBehaelterData))
                    }else{
                        setBehaelterData()
                    }

                })
            }  
            else if(url_id != '' && url_id != null){
                client.get(
                    kunden_data_endpoint,
                ).then(function(res) {
                    if(!currentUser){
                        setTypData('Leerung')    
                    }
                        
                    const selectedBehaelterIdFind = res.data.behaelters.find((behaelter)=> behaelter.uuid==url_id)
                    setProduktData(selectedBehaelterIdFind.id)
                    
                    setKundenData(res.data);

                    if(typData == 'Abziehen'|| typData == 'Leerung'){
                        setBehaelterData(res.data.behaelters)
                    }
                    else if(typData == 'Gestellung' || typData == 'Abholung'){
                        client.get(
                            "/bestellformular/api/v1/all_produkte/",
                        
                        ).then(function(res) {
                            setBehaelterData(res.data)
                        })
                    }else{
                        setBehaelterData()
                    }    
                })
                .catch((error) => {
                    navigate("/login");
                });
            }   
        }  
    }, [typData, loading]);

    function produktCard(e) {
        e.preventDefault();
        
        const selectedProdukt = behaelterData.find((produkt)=> produkt.id==produktData) 
        setProduktCardData([
            ...produktCardData,
            {randomId:randomId,
            typ:typData,
            produkt:selectedProdukt,
            ausführungsdatum:ausführungsdatum,
            lose_ladung:loseLadung}
        ]) 
        setCardEmpty(false);
    }
      
    return (  
        <div>
        <div className='md:flex items-center justify-between bg-white py-4 md:px-10 px-7'>
           
            <div className='font-bold text-2xl cursor-pointer flex items-center gap-1'>
                <img src={logo}></img>
                <span>Bestellformular</span>
            </div>
        </div>

        <div className='container mt-5'>
            <form className="lg:w-6/12 xs:w-full xs:mx-2" onSubmit={(e) => auftragSpeichern(e)}>
                <div className="flex">
                    <div className="flex items-center mb-4 mr-6">
                        <input id="messe" type="radio" name="kundentyp" value="Messe" checked={'Messe' === kundenData.kundentyp} className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"disabled />
                        <label for="messe" className="block ms-2  text-sm font-medium text-gray-900 dark:text-gray-300">
                        Messe
                        </label>
                    </div>
                    <div className="flex items-center mb-4">
                        <input id="aussteller" type="radio" name="kundentyp" value="Aussteller" checked={'Aussteller' === kundenData.kundentyp} className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600" disabled/>
                        <label for="aussteller" className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Aussteller
                        </label>
                    </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-5 group">
                        <label for="bezeichnung" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bezeichnung</label>
                        <input type="text" id="bezeichnung" value={kundenData.bezeichnung} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" disabled/>
                    </div>
                    <div className="relative z-0 w-full mb-5 group">
                        <label for="halle" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Halle</label>
                        <input type="text" id="halle" value={kundenData.halle} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" disabled/>
                    </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-5 group">
                        <label for="stand" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Stand</label>
                        <input type="text" id="stand" value={kundenData.stand} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" disabled/>
                    </div>
                   
                </div>
                <div className="grid md:grid-cols-4 md:gap-6">
                    <div>
                        <label for="typ" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Typ</label>
                        {currentUser || offline ?
                        <select onChange={(e) => setTypData(e.target.value)} id="typ" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 " 
                        >
                            <option value="">...</option>
                            <option value="Gestellung">Gestellung</option>
                            <option value="Abziehen">Abziehen</option>             
                            <option value="Leerung">Leerung</option>
                            <option value="Abholung">Abholung</option>
       
                        </select>
                        :
                        <select disabled onChange={(e) => setTypData(e.target.value)} id="typ" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 " 
                        value='Leerung'>           
                             <option value="Leerung" selected>Leerung</option>
     
                        </select> 
                         } 
                    </div > 
                    <div>
                        <label for="behaelter" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Wählen Sie den Behälter</label>
                        <select onChange={(e) => setProduktData(e.target.value)} id="behaelter" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={produktData}>
                        <option value="">...</option>
                        {behaelterData &&
                            behaelterData.sort((a, b) => a.abfallart.localeCompare(b.abfallart))
                            .map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.abfallart +' '+ item.behaelter_groesse +' '+ item.einheit}
                                </option>
                            ))
                        }
                        </select>   
                    </div >
                   
                    
                    <div >
                        <label for="datum" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Datum</label>
                        <DatePicker selected={ausführungsdatum} className="border-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" dateFormat='dd/MM/yyyy' onChange={(date) => setAusführungsdatum(date)} />
                    </div>
                    { 
                     showLoseLadung== true ?
                        <div >
                            <label for="lose_ladung" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Menge</label>
                            <input type="text" id="lose_ladung"onChange={(e) => setLoseLadung(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                    :
                       ''

                    } 
                     
                </div>
                {cardEmpty && <p className='text-red-500 text-xs'>Bitte wählen Sie Produkt aus</p>}
                <button type="submit" onClick={e => produktCard(e)} className=" mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Hinzufügen</button> 
                
                <Produktcard produktCardData={produktCardData} setProduktCardData={setProduktCardData} behaelterData={behaelterData} setBehaelterData={setBehaelterData}></Produktcard>
                
                <div className='mt-10'>   
                    <label for="bemerkung" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bemerkung</label>
                    <textarea id="bemerkung" onChange={(e) => setBemerkungsfeld(e.target.value)} rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Schreib hier..."></textarea>
                </div>

                <div className='mt-10'>
                <label for="unterschrift" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Unterschrift</label>
                    <div  className="border-solid border-black border-2 w-[22rem]">
                    <SignatureCanvas 
                        canvasProps={{height: 200,width:360, className: 'sigCanvas'}}
                        ref={sigPadRef}
                    />
                    </div>
                    {signatureEmpty && <p className='text-red-500 text-xs'>Bitte leiste deine Unterschrift.</p>}
                    <br/>
                    <button  className='bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l ' onClick={e => handleClear(e)}>Löschen</button>
                </div>

                <button type="submit"  className=" mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Speichern</button> 
  
            </form>
        </div>
        <Footer></Footer>

        </div>
    );
}

export default Bestellformular;