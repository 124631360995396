import React from 'react';
import '../App.css';

function Produktcard({produktCardData, setProduktCardData}){
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('de-DE', options);
      };

    function cardLoeschen(uniqueId) {
        setProduktCardData (produktCardData.filter(i => i.randomId !== uniqueId))
    }

    return(
        <div>{
                produktCardData.map((item) => (
                    <div key={item.produkt.id} id={item.randomId} className=" grid md:grid-cols-3 md:gap-6 mt-4 p-6 bg-white border border-gray-200 rounded-lg shadow-xl dark:bg-gray-800 dark:border-gray-700">
                         { item.lose_ladung == 1 ? <p></p> : <p>{item.lose_ladung}</p>}
                        <p>{item.produkt.abfallart + ' '+ item.produkt.behaelter_groesse + ' ' + item.produkt.einheit }</p>     
                        <p>{item.typ}</p> 
                        <p>{formatDate(item.ausführungsdatum)}</p>  
                        <p></p> 
                        <p></p>
                        <button type="button" onClick={() => cardLoeschen(item.randomId)} className="w-24 text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center lg:ml-16  me-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">Löschen</button>
                    </div>          
                ))
            }
        </div>
    )
}
export default Produktcard;