import React from 'react';
import { Page, Text, Document, StyleSheet, Image } from '@react-pdf/renderer';


const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    orientation: 'landscape',

  },
  subtitle: {
    marginBottom: 15,
    textAlign: 'center',
    hyphens:'none',  
  },
  qrImage: {
    width: "200px",
    height: "200px",
    marginVertical: 15,
    marginHorizontal: 290,
  },
  logo:{
    width: "170px",
    marginVertical: 10,
    marginHorizontal: 300,
  }
  
});


export function BehaelterPdf (props){
    const dataUrl = document.getElementById(props.data.behaelterUuid).toDataURL();
return(
    <Document>
        <Page size="A4" style={styles.page} orientation="landscape">
            <Image style={styles.logo} src="https://upload.wikimedia.org/wikipedia/commons/b/b2/Logo_Berlin_Recycling_GmbH.png" />
            <Text style={styles.subtitle}>Name: {props.data.bezeichnung}</Text>
            <Text style={styles.subtitle}>Behälter Id: {props.data.behaelterId}</Text>
            <Text style={styles.subtitle}>Stand: {props.data.stand}</Text>
            <Text style={styles.subtitle}>Halle: {props.data.halle}</Text>
            
            <Image style={styles.qrImage} allowDangerousPaths src={dataUrl}/>
        </Page>
    </Document>
    )
}
    
export default BehaelterPdf;