import React from 'react';
import '../App.css';
import Navbar from '../components/Navbar';
import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alleKunden, userCheck, healty } from '../features/authenticationSlice';
import CollapsibleTable from '../components/CollapsibleTable'
import axios from '../axiosConfig';
import env from "react-dotenv";

function Uebersichtseite(){
  const currentUser = useSelector(state => state.currentUser)
  const offline = useSelector(state => state.offline)
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()
    // die Aufträge, die als Offline erstellt wurden, werden hier genommen und zum Backend geschickt
    const client = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL
    })
    let localstorage_auftragData = localStorage.getItem('auftragData')

    if (!offline && localstorage_auftragData != null){
      const offlineAuftraege = JSON.parse(localstorage_auftragData)
      for (let i=0; i<offlineAuftraege.length; i++){
          client.post('/bestellformular/api/v1/auftrag/', offlineAuftraege[i])
      }   
      localStorage.removeItem('auftragData');
    }   
    
  
  useEffect(() => {
      dispatch(healty()) 
      dispatch(userCheck()) 
      dispatch(alleKunden())   
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, []);
 
  if (loading == false){
    if(currentUser || offline){
      return(
        <div>
          <Navbar></Navbar>
          <CollapsibleTable></CollapsibleTable>      
        </div>  
        )
      }
      return(
      <Navigate to='/login'/>
      )
  }
  
}

export default Uebersichtseite;