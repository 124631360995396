import React from 'react';
import '../App.css';
import {useLocation} from 'react-router-dom';

function Successpage(){
  const location = useLocation();
    return(
    <div>
        <h1 className='mt-6 text-3xl text-center '>
            Ihre Bestellung wurde erfolgreich aufgegeben
        </h1>
    </div>  
    )
}
  
  


export default Successpage;