import './App.css';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {userCheck} from './features/authenticationSlice';


function App(){console.log('APP:JS GIRIYO')
  const dispatch = useDispatch()
  
  useEffect(() => {
      dispatch(userCheck())  
  }, []);
    
  const currentUser = useSelector(state => state.currentUser)
  const offline = useSelector(state => state.offline)
 
  if(currentUser || offline){
    return(
      <div>
        <Navigate to='/uebersichtseite'/>
      </div> 
      )
   }
    return(
      <Navigate to='/login'/>
    )
  
}

export default App;

