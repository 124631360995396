import React from 'react';
import QRCode from 'qrcode.react';

export function QRGenerator (props){

return(
    <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
        <QRCode
        id={props.behaelterUuid}
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={'https://bestellung-ila.recycling-team-berlin.de/bestellformular?url_id='+props.behaelterUuid}
        viewBox={`0 0 256 256`}
        />
    </div>
   
    )
}
    
export default QRGenerator;