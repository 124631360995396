import React from 'react';
import BehaelterPdf from './BehaelterPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import QRGenerator from './QRGenerator';

export function PdfDownloadLinkComponent (props){
  
return(
    <>
    <div hidden>
        <QRGenerator behaelterUuid={props.behaelterUuid}/>
    </div>
    
    <PDFDownloadLink  document={<BehaelterPdf  data={{bezeichnung:props.bezeichnung, halle:props.halle, stand:props.stand, behaelterId:props.behaelterId, behaelterUuid:props.behaelterUuid}} />} fileName="behaelter.pdf">
        {({loading}) => (loading ? 'Loading document...' : 'Download PDF!')}
    </PDFDownloadLink>
    </>  
    )
}
    
export default PdfDownloadLinkComponent;