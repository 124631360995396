import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Bestellformular from './components/Bestellformular';
import Login from './pages/Login';
import { Provider } from 'react-redux';
import store from'../src/app/store'
import Uebersichtseite from './pages/Uebersichtseite';
import Errorpage from './pages/Errorpage';
import swdev from './swdev';
import Successpage from './pages/Successpage';

const router = createBrowserRouter([
  {path:"/", element:<App/>},
  {path:"/bestellformular", element:<Bestellformular/>},
  {path:"/login", element:<Login/>},
  {path:"/uebersichtseite", element:<Uebersichtseite/>},
  {path:"/errorpage", element:<Errorpage/>},
  {path:"/successpage", element:<Successpage/>}

])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Provider store={store}>
     <RouterProvider router={router}/>
     </Provider>
   
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
swdev();



